import { addPropertyControls, ControlType } from "framer"
import { JobPosting, WithContext } from "schema-dts"
import {
    useEffect,
    useState,
    useRef,
    useMemo,
    isValidElement,
    Children,
    Fragment,
    ReactElement,
    ReactNode,
} from "react"

import { createRoot } from "react-dom/client"
import { flushSync } from "react-dom"

export default function JobPostingSchema(props) {
    const json: WithContext<JobPosting> = useMemo(
        () => ({
            "@context": "https://schema.org",
            "@type": "JobPosting",
            title: props.title,
            description: convertToHTML(props.description),
            datePosted: props.datePosted
                ? props.datePosted.split("T")[0]
                : undefined,
            validThrough: props.validThrough,
            employmentType: props.employmentType
                ? props.employmentType.split(",")
                : undefined,
            hiringOrganization: {
                "@type": "Organization",
                "@id": "https://www.krauss-gmbh.com/#organization", // Reusing the
                name: "KRAUSS Neukundengewinnung",
            },
            identifier: {
                "@type": "PropertyValue",
                name: "KRAUSS Neukundengewinnung",
                value: "https://www.krauss-gmbh.com/#organization",
            },
            jobLocation: {
                "@type": "Place",
                "@id": "https://www.krauss-gmbh.com/#location", // Reusing the location ID
                address: {
                    "@type": "PostalAddress",
                    streetAddress: "Ohmstraße 8",
                    addressLocality: "Landsberg am Lech",
                    addressRegion: "Bayern",
                    postalCode: "86899",
                    addressCountry: "DE",
                },
            },
            educationRequirements: "no requirements",
            directApply: true,
        }),
        [props]
    )

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
        />
    )
}

addPropertyControls(JobPostingSchema, {
    title: {
        title: "Title",
        type: ControlType.String,
    },
    description: {
        title: "Content",
        //@ts-ignore
        type: ControlType.RichText,
    },
    datePosted: {
        title: "Date Posted",
        type: ControlType.Date,
    },
    validThrough: {
        title: "Valid Until",
        type: ControlType.Date,
    },
    employmentType: {
        options: ["FULL_TIME", "PART_TIME", "FULL_TIME,PART_TIME"],
        type: ControlType.Enum,
        defaultValue: "FULL_TIME,PART_TIME",
    },
})
// Set of self-closing HTML tags for optimization
const selfClosingTags = new Set([
    "br",
    "hr",
    "img",
    "input",
    "meta",
    "link",
    "area",
    "base",
    "col",
    "command",
    "embed",
    "source",
    "track",
    "wbr",
])

/**
 * Optimized function to convert React elements (including Fragments and custom components) to HTML strings.
 * Handles all types of React elements, including custom components.
 */
export function convertToHTML(element: ReactNode): string {
    if (element === null || element === undefined || element === false) {
        return ""
    }

    // Handle simple types (strings, numbers)
    if (typeof element === "string" || typeof element === "number") {
        return String(element)
    }

    // If it's a React element (HTML or custom component)
    if (isValidElement(element)) {
        const { type, props } = element as ReactElement

        // Handling Fragments
        if (type === Fragment) {
            return Children.toArray(props.children).map(convertToHTML).join("")
        }

        // If it's an HTML element (tag name)
        if (typeof type === "string") {
            const tagName = type.toLowerCase()
            const isSelfClosing = selfClosingTags.has(tagName)
            let htmlString = `<${tagName}`

            // If the element is not self-closing, handle children and closing tag
            if (!isSelfClosing && props.children) {
                const children = Children.toArray(props.children)
                htmlString += `>${children.map(convertToHTML).join("")}</${tagName}>`
            } else {
                htmlString += " />"
            }

            return htmlString
        }

        // For custom components (functions, classes)
        if (typeof type === "function") {
            const children = Children.toArray(props.children)
            return children.map(convertToHTML).join("")
        }
    }

    // Fallback: return empty string for unsupported types
    return ""
}
